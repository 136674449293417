<template>
  <div>
    <page-header-admin
      class="mb-2"
      :title="$t('Pending edit requests')"
    />
    <b-card
      no-body
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Records') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                type="search"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        :items="lastRequestOfMentors"
        responsive
        :fields="fields"
        :filter="searchQuery"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="''"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
        no-provider-sorting
        no-provider-paging
        no-provider-filtering
        no-sort-reset
        :per-page="perPage"
        :current-page="currentPage"
      >
        <template #head()="{label}">
          {{ label | upper }}
        </template>

        <!-- Column: photo -->
        <template #cell(photo_full_path)="data">
          <b-avatar
            class="grayscale-avatar"
            :src="data.item.mentor.user.photo_full_path"
          />
        </template>

        <!-- Column: fullname -->
        <!-- eslint-disable-next-line -->
        <template #cell(mentor.user.surname)="data">
          <b-link :to="{ name: 'admin-pending-edit-requests-of-mentor', params: { mentor_id: data.item.mentor.id }}">
            {{ getMentorVisibleFullName(data.item.mentor) }}
          </b-link>
        </template>

        <!-- Column: request_date -->
        <template #cell(request_date)="{item}">
          {{ item.request_date | formatGreekDate({ month: '2-digit', year: 'numeric', day: '2-digit' }) }}
        </template>
      </b-table>
    </b-card>
    <div>
      <b-row>
        <b-col
          class="d-flex justify-content-end"
        >
          <b-pagination
            v-model="currentPage"
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            :per-page="perPage"
            first-number
            last-number
            :total-rows="totalRows"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BCard,
  BCol,
  BFormInput,
  BLink,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { usePendingEditRequest, useMentorHelpers } from '@mentoring-platform/composables'
import { upper, formatGreekDate } from '@mentoring-platform/utils/filters'
import { mentorStatuses } from '@mentoring-platform/constants'
import PageHeaderAdmin from '@mentoring-platform/views/components/blocks/PageHeaderAdmin.vue'

export default {
  components: {
    BAvatar,
    BCard,
    BCol,
    BFormInput,
    BLink,
    BPagination,
    BRow,
    BTable,
    PageHeaderAdmin,
    vSelect
  },
  filters: {
    formatGreekDate,
    upper
  },
  data() {
    return {
      mentorStatuses,
      perPage: 50,
      perPageOptions: [10, 25, 50, 100],
      currentPage: 1,
      searchQuery: null,
      sortBy: 'request_date',
      isSortDirDesc: true
    }
  },
  computed: {
    fields() {
      return [{
        key: 'photo_full_path',
        label: this.$t('Photo'),
        tdClass: 'mentor-avatar'
      },
      {
        key: 'mentor.user.surname',
        label: this.$t('Full name'),
        sortable: true,
      },
      {
        key: 'request_date',
        label: this.$t('Request date'),
        sortable: true
      }]
    },
    totalRows() {
      return this.lastRequestOfMentors.length
    }
  },
  setup() {
    const {
      getMentorVisibleFullName
    } = useMentorHelpers()
    const {
      getPendingEditRequests,
      lastRequestOfMentors
    } = usePendingEditRequest()
    getPendingEditRequests()

    return {
      getMentorVisibleFullName,
      lastRequestOfMentors,
      getPendingEditRequests
    }
  }
}
</script>

<style lang="scss">
.mentor-avatar {
  width: 100px;
}
</style>
